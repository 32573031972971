import React, { useState, useEffect, useRef, useContext } from "react";

import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import AddIcon from "@material-ui/icons/Add";
import LabelIcon from "@material-ui/icons/Label";
import StarIcon from "@material-ui/icons/Star";
import GroupIcon from "@material-ui/icons/Group";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import AndroidIcon from "@material-ui/icons/Android";
import DoneIcon from "@material-ui/icons/Done";
import LabelAssignTicketModal from "../LabelAssignTicketModal";
import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import MarkdownWrapper from "../MarkdownWrapper";
import {
  Chip,
  IconButton,
  ListItemSecondaryAction,
  Tooltip,
} from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import { Can } from "../Can";
import { OfflineBolt } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  ticket: {
    position: "relative",
  },

  ticketUnread: {
    background: "linear-gradient(to right, rgba(87, 187, 137, 0.25), transparent)",
  },

  pendingTicket: {},

  noTicketsDiv: {
    display: "flex",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  noTicketsText: {
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
  },

  noTicketsTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
  },

  contactNameWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },

  lastMessageTime: {
    justifySelf: "flex-end",
  },

  closedBadge: {},

  contactLastMessage: {
    paddingRight: 20,
  },

  newMessagesCount: {
    alignSelf: "center",
    marginRight: 8,
    marginLeft: "auto",
  },

  badgeStyle: {
    color: "white",
    backgroundColor: green[500],
  },

  acceptButton: {
    position: "absolute",
    left: 0,
    transform: "translate(-50%, -50%)",
  },

  ticketQueueColor: {
    flex: "none",
    width: "8px",
    height: "100%",
    position: "absolute",
    top: "0%",
    left: "0%",
  },

  userTag: {
    background: "#2576D2",
    color: "#ffffff",
    border: "1px solid #CCC",
    padding: 1,
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 10,
    fontSize: "0.8em",
  },

  botTag: {
    background: "#2591D2",
    color: "#ffffff",
    border: "1px solid #CCC",
    padding: 1,
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 10,
    fontSize: "0.8em",
  },

  maskedLink: {
    color: "#6bcbef",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },

  radiusDot: {
    "& .MuiBadge-badge": {
      borderRadius: 2,
      position: "inherit",
      height: 16,
      margin: 2,
      padding: 3,
      fontSize: 10,
    },
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      transform: "scale(1) translate(0%, -40%)",
    },
  },

  renderTicketInfo: {
    position: "relative",
    transform: "unset",
    right: 0,
    top: 0,
  },

  lastMessageFromMeAvatar: {
    minWidth: "0",
    marginRight: "1rem",
    padding: "0.2rem",
    [theme.breakpoints.down("sm")]: {
      marginRight: "0.5rem",
    },
  },

  lastMessageNotFromMeAvatar: {
    border: "3px solid",
    borderColor: theme.palette.primary.main,
    borderRadius: "50%",
  },
}));

const TicketListItem = ({
  ticket,
  isPinned,
  onTogglePin,
  loadingPinnedTickets,
  linkProtectionEnabled,
  disableHyperlink,
  maskLink,
  isGroup,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { ticketId } = useParams();
  const isMounted = useRef(true);
  const { user } = useContext(AuthContext);
  const [openAssignLabelModal, setOpenAssignLabelModal] = useState(false);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleAcepptTicket = async (id) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "open",
        userId: user?.id,
      });
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }
    history.push(`/tickets/${id}`);
  };

  const handleSelectTicket = (id) => {
    history.push(`/tickets/${id}`);
  };

  const maskingLink = (text) => {
    const urlPattern = /https?:\/\/\S+/gi;

    if (urlPattern.test(text) && linkProtectionEnabled === "enabled") {
      let replacedText;

      if (disableHyperlink === "enabled") {
        replacedText = text.replace(
          urlPattern,
          `<span class="${classes.maskedLink}">$&</span>`
        );
      } else if (maskLink === "enabled") {
        replacedText = text.replace(
          urlPattern,
          "<span><b>* Link Protected *</b></span>"
        );
      }

      // The function dangerouslySetInnerHTML is used here to parse our replacedText into HTML.
      // Note: Be cautious with dangerouslySetInnerHTML as it can open up to XSS attacks if misused.
      return <span dangerouslySetInnerHTML={{ __html: replacedText }} />;
    }

    return <MarkdownWrapper>{text}</MarkdownWrapper>;
  };

  const handleAddLabel = () => {
    setOpenAssignLabelModal(true);
  };

  const handleClose = () => {
    setOpenAssignLabelModal(false);
  };

  const renderTicketInfo = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: ticket.isBot || ticket.status === "pending" ? 10 : 0,
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
            {ticket.isBot && (
              <Tooltip title={i18n.t("ticketsList.bot")}>
                <AndroidIcon
                  fontSize="small"
                  style={{
                    color: "#fff",
                    backgroundColor: green[700],
                    cursor: "pointer",
                    padding: 2,
                    height: 23,
                    width: 23,
                    fontSize: 12,
                    borderRadius: 50,
                  }}
                />
              </Tooltip>
            )}

            {ticket.status === "pending" && (
              <Can
                role={user.profile}
                perform="tickets:accept-button"
                yes={() => (
                  <ButtonWithSpinner
                    color="primary"
                    variant="contained"
                    size="small"
                    loading={loading}
                    onClick={(e) => handleAcepptTicket(ticket.id)}
                    startIcon={<DoneIcon />}
                  >
                    {i18n.t("ticketsList.buttons.accept")}
                  </ButtonWithSpinner>
                )}
              />
            )}
          </div>
          <div
            style={{ display: "flex", gap: 5, flexWrap: "wrap", width: "100%" }}
          >
            {ticket.status === "closed" && (
              <Chip
                avatar={
                  <Avatar
                    style={{
                      backgroundColor: "#ff0000",
                      color: "#fff",
                    }}
                  >
                    <LabelIcon style={{ color: "#ff0000" }} />
                  </Avatar>
                }
                label={i18n.t("ticketsList.closed")}
                size="small"
                style={{ borderColor: "grey", color: "#000" }}
                variant="outlined"
              />
            )}

            {ticket?.labels && ticket.labels.length > 0
              ? ticket.labels.map((label) => (
                  <Chip
                    key={label.id}
                    avatar={
                      <Avatar
                        style={{
                          backgroundColor: label.color,
                          color: "#FFF",
                        }}
                      >
                        <LabelIcon style={{ color: label.color }} />
                      </Avatar>
                    }
                    label={label.name}
                    size="small"
                    style={{ borderColor: "grey", color: "#000" }}
                    variant="outlined"
                    onClick={() => handleAddLabel(ticket)}
                  />
                ))
              : null}

            <Chip
              size="small"
              color="primary"
              variant="outlined"
              icon={<AddIcon />}
              clickable
              label={i18n.t("ticketsList.label.add")}
              onClick={() => handleAddLabel(ticket)}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <React.Fragment key={ticket.id}>
      <LabelAssignTicketModal
        open={openAssignLabelModal}
        onClose={handleClose}
        ticket={ticket}
      />
      <ListItem
        dense
        button
        onClick={(e) => {
          handleSelectTicket(ticket.id);
        }}
        selected={ticketId && +ticketId === ticket.id}
        className={clsx(classes.ticket, {
          [classes.pendingTicket]: ticket.status === "pending",
          [classes.ticketUnread]: !ticket.lastMessageFromMe,
        })}
      >
        <Tooltip
          arrow
          placement="right"
          title={ticket.queue?.name || "No queue"}
        >
          <span
            style={{ backgroundColor: ticket.queue?.color || "#7C7C7C" }}
            className={classes.ticketQueueColor}
          ></span>
        </Tooltip>
        <ListItemAvatar
          className={clsx(classes.lastMessageFromMeAvatar, {
            [classes.lastMessageNotFromMeAvatar]: !ticket.lastMessageFromMe,
          })}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Avatar src={ticket?.contact?.profilePicUrl} />
            <Typography
              variant="caption"
              style={{
                position: "absolute",
                bottom: "-45%",
                backgroundColor: "white",
                padding: "0 4px",
                borderRadius: 4,
                fontSize: "0.7rem",
              }}
            >
              #{ticket.id}
            </Typography>
          </div>
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={
            <span className={classes.contactNameWrapper}>
              <Typography
                noWrap
                component="span"
                variant="body2"
                color="textPrimary"
                style={{ display: "flex", alignItems: "center" }}
              >
                {ticket.channel === "whatsappOfficial" && (
                  <Tooltip
                    title={
                      i18n.t("ticketsList.connectionTitle") +
                      " " +
                      ticket.whatsapp?.name
                    }
                    placement="top-start"
                    arrow
                  >
                    <WhatsAppIcon
                      fontSize="inherit"
                      style={{ color: ticket.whatsapp?.color || "green", marginRight: 5 }}
                    />
                  </Tooltip>
                )}
                {ticket.channel === "whatsappUnofficial" && (
                  <Tooltip
                    title={
                      i18n.t("ticketsList.connectionTitle") +
                      " " +
                      ticket.whatsapp?.name
                    }
                    placement="top-start"
                    arrow
                  >
                    <OfflineBolt
                      fontSize="inherit"
                      style={{ color: ticket.whatsapp?.color || "orange", marginRight: 5 }}
                    />
                  </Tooltip>
                )}
                {isGroup && (
                  <Tooltip title={i18n.t("ticketsList.groupTicket")}>
                    <GroupIcon
                      fontSize="inherit"
                      style={{ marginRight: 5, color: "grey" }}
                    />
                  </Tooltip>
                )}

                {isGroup ? `(G) ${ticket.contact?.name}` : ticket.contact?.name}
              </Typography>
              {ticket.lastMessage && (
                <Typography
                  className={classes.lastMessageTime}
                  component="span"
                  variant="body2"
                  color="textSecondary"
                >
                  {isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
                    <>{format(parseISO(ticket.updatedAt), "HH:mm")}</>
                  ) : (
                    <>{format(parseISO(ticket.updatedAt), "dd/MM/yyyy")}</>
                  )}

                  {ticket.status === "open" && (
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        onTogglePin();
                      }}
                      style={{ marginLeft: 4, padding: 4 }}
                      disabled={loadingPinnedTickets}
                    >
                      {isPinned ? (
                        <StarIcon fontSize="small" color="secondary" />
                      ) : (
                        <StarBorderIcon fontSize="small" />
                      )}
                    </IconButton>
                  )}
                </Typography>
              )}
            </span>
          }
          secondary={
            <span className={classes.contactNameWrapper}>
              <Typography
                className={classes.contactLastMessage}
                noWrap
                component="span"
                variant="body2"
                color="textSecondary"
              >
                {ticket.lastMessage ? maskingLink(ticket.lastMessage) : <br />}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: 2,
                    marginTop: 5,
                  }}
                >
                  <ListItemSecondaryAction className={classes.renderTicketInfo}>
                    <Box>{renderTicketInfo()}</Box>
                  </ListItemSecondaryAction>
                </div>
              </Typography>

              <Badge
                overlap="rectangular"
                className={classes.newMessagesCount}
                badgeContent={ticket.unreadMessages}
                classes={{
                  badge: classes.badgeStyle,
                }}
              />
            </span>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </React.Fragment>
  );
};

export default TicketListItem;
